import { Injectable } from '@angular/core'

import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private isProduction: boolean = environment.production

  log(message?: any, ...optionalParams: any[]): void {
    if (!this.isProduction) {
      console.log(message, ...optionalParams)
    }
  }

  error(message?: any, ...optionalParams: any[]): void {
    console.error(message, ...optionalParams)
  }
}
